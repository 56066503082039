import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../Sections/Footer";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import CourseImg4 from "../../assets/img/courses/Course4.png";

export default function Reiki() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper>
      <ContentWrapper>
        <BackButton onClick={handleGoBack}>
          <FiArrowLeft /> Go Back
        </BackButton>
        <MainHeading>
          <Title>Pendulum (Dowsing)</Title>
          <CourseImage4Styled src={CourseImg4} alt="Pendulum" />
        </MainHeading>
        <SecondaryHeading>
          <Paragraph>
            Our Reiki Pendulum Dowsing course offers a comprehensive guide to
            understanding and harnessing the power of the pendulum. Delve into
            the 12 primary life directions often affected by negative energies.
            Learn practical techniques to identify and address these imbalances,
            empowering you to find answers to life's questions and make informed
            decisions. Gain the tools to become self-reliant and navigate your
            path with confidence.
          </Paragraph>

          <SubHeading>The 12 directions are as follows</SubHeading>
          <BulletList>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Your Own Karma.</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Effects of Planetary System with Respect to Your Own Karma.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>From Land.</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>From House, Office, or Factory Vastu.</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Effects of Positive & Negative Thoughts from Different People.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Genetic Defect or Pitru Dosh.</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Effects of Thoughts & Emotions from All People, Either Dead or
                Alive.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Blessings & Curses of Deities or Evil Power.
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Effects of your Own Thought Flow.</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Destiny.</BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>
                Effects for Achieving Goals & Efforts for Bounded Duty (Kartavya
                & Karma Yog).
              </BulletText>
            </BulletItem>
            <BulletItem>
              <BulletSymbol>■</BulletSymbol>
              <BulletText>Faith in Yourself & God.</BulletText>
            </BulletItem>
          </BulletList>
        </SecondaryHeading>
      </ContentWrapper>
      <Footer />
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 40px 0 0 0; // Ensure no padding is applied here
  margin: 0; // Remove margin around the wrapper
  width: 100%; // Ensure the wrapper takes full width
`;

const ContentWrapper = styled.div`
  flex: 1;
  max-width: 900px; // You may keep this if you want to constrain the content width
  margin: 0 auto;
  padding: 0 40px; // Remove any extra padding here if present
  text-align: center;
  width: 100%; // Ensure it uses the full available width
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #04016a;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
    margin-top: 60px;
  }
`;

const CourseImage4Styled = styled.img`
  width: 100%;
  max-width: 600px;
  margin: 20px 0;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  background-color: #04016a;
  padding: 15px 20px;
  border-radius: 8px;
  margin: 30px 0 15px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 900px) {
    font-size: 1.8rem;
  }

  @media (max-width: 600px) {
    font-size: 1.6rem;
    padding: 10px 15px;
  }

  @media (max-width: 400px) {
    font-size: 1.4rem;
    padding: 8px 12px;
  }
`;

const Paragraph = styled.p`
  margin: 20px 0;
  line-height: 1.6;
  font-size: 1.1rem;
  background-color: #e8e0f8;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  max-width: 90%;
  margin: 20px auto;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const BulletList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0 40px;
  max-width: 90%;
  margin: 20px auto;
`;

const BulletItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  line-height: 1.5;
  padding-left: 10px;
  text-align: left;
  font-size: 1.1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const BulletSymbol = styled.span`
  color: #6e8cc3;
  font-size: 1.5rem;
  margin-right: 10px;
`;

const BulletText = styled.span`
  flex: 1;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e1e7ff;
  }
`;

const MainHeading = styled.div`
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const SecondaryHeading = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: transparent;
  border: 2px solid #6e8cc3;
  color: #6e8cc3;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background: #6e8cc3;
    color: white;
    transform: translateX(-5px);
  }

  svg {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 768px) {
    top: 10px;
    left: 15px;
    font-size: 1rem;
    padding: 8px 16px;
  }

  @media (max-width: 480px) {
    top: 15px;
    left: 10px;
    font-size: 0.9rem;
    padding: 8px 12px;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-color: #6e8cc3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;

  &:hover {
    background-color: #4a6ca3;
  }

  @media (max-width: 768px) {
    bottom: 60px;
  }

  @media (max-width: 480px) {
    bottom: 80px;
  }
`;
