import * as React from "react";
import Companylogo from "../../assets/img/logo.svg";

function SvgComponent(props) {
  return (
    <img src={Companylogo} alt="Company Logo" height="50px" width="auto" />
  );
}

export default SvgComponent;
