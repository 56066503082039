import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BackgroundImage from "../../assets/img/office.jpg";

const Store = () => {
  const navigate = useNavigate();

  // Save scroll position before navigating away
  const handleGoBack = () => {
    const scrollPosition = window.scrollY; // Get current vertical scroll position
    sessionStorage.setItem("scrollPosition", scrollPosition); // Save position in sessionStorage
    navigate(-1); // Navigate back
  };

  // Restore scroll position when the component mounts
  useEffect(() => {
    const savedPosition = sessionStorage.getItem("scrollPosition");
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10)); // Restore saved position
    }
  }, []);

  return (
    <StoreWrapper>
      <GoBackButton onClick={handleGoBack}>←</GoBackButton>
      <BackgroundImg
        src={BackgroundImage}
        alt="Office Background"
        loading="lazy"
      />
      <MapLink
        href="https://maps.app.goo.gl/4eFTdPY752pYHLX1A"
        rel="noopener noreferrer"
      >
        🗺️✨ View Our Location 🌟🏢
      </MapLink>
    </StoreWrapper>
  );
};

// Styled Components

const StoreWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image covers the area without stretching */
  object-position: center center; /* Keeps image centered */
  z-index: -1; /* Ensure image stays behind content */
`;

const MapLink = styled.a`
  padding: 20px 40px;
  background: rgba(255, 255, 255, 0.8); /* Light background for readability */
  border-radius: 8px;
  font-size: 1.5em;
  color: #2c3e50;
  text-decoration: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;

  /* Hover Effect */
  &:hover {
    background: rgba(255, 255, 255, 1); /* Brighten background on hover */
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3); /* Slightly more prominent shadow on hover */
  }

  /* Responsive Styles for Tablets and Below */
  @media (max-width: 768px) {
    padding: 15px 30px; /* Adjust padding for tablets */
    font-size: 1.3em; /* Adjust font size for medium screens */
  }

  @media (max-width: 480px) {
    padding: 12px 25px; /* Adjust padding for mobile screens */
    font-size: 1.1em; /* Adjust font size for small screens */
  }
`;

const GoBackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  background: transparent; /* Make background transparent */
  color: #ffd700; /* Bright golden color for the arrow */
  border: none; /* No border */
  font-size: 2em; /* Larger size for visibility */
  font-weight: bold;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
  text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8); /* Subtle glow effect for text */

  /* Hover Effect */
  &:hover {
    background: transparent; /* Keep background transparent on hover */
    color: #fff; /* Change color to white on hover */
    transform: scale(1.1); /* Slightly enlarge button */
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 1); /* Stronger glow on hover */
    box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.3); /* Subtle glow effect */
  }

  /* Active/Pressed State */
  &:active {
    transform: scale(1); /* Reset scale on active (when clicked) */
    color: #ffd700; /* Keep golden color on active */
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.9); /* More intense glow on active */
  }

  @media (max-width: 480px) {
    font-size: 1.5em; /* Adjust font size for smaller screens */
    padding: 8px 16px; /* Adjust padding for small screens */
  }
`;

export default Store;
