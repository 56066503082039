import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import styled from "styled-components";

import consultationImg from "../../assets/img/services/consultation.svg";
import reikiImg from "../../assets/img/services/reiki.svg";
import medicineFreeImg from "../../assets/img/services/no-medicine.svg";
import energioImg from "../../assets/img/services/land.svg";

export default function Services() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowButton(window.scrollY > 300);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Wrapper id="services">
      <AnchorOffset /> {/* This ensures proper scrolling */}
      <div className="">
        <div className="container">
          <HeaderInfo>
            <Title>Our Awesome Services</Title>
            <Description>
              We provide personalized Reiki Courses, spiritual guidance, and
              wellness programs designed to promote inner peace, balance, and
              overall well-being.
            </Description>
          </HeaderInfo>
          <CardsContainer>
            {serviceData.map((service, index) => (
              <Card key={index}>
                <CardImage src={service.imageUrl} alt={service.title} />
                <CardContent>
                  <h2>{service.title}</h2>
                  <p>{service.subtitle}</p>
                </CardContent>
              </Card>
            ))}
          </CardsContainer>

          {/* Learn More Button */}
          <LearnMoreButtonContainer>
            <Link to="/all-services">
              <LearnMoreButton>Learn More</LearnMoreButton>
            </Link>
          </LearnMoreButtonContainer>
        </div>
      </div>
      <BackToTopButton show={showButton} onClick={scrollToTop}>
        ↑
      </BackToTopButton>
    </Wrapper>
  );
}

const serviceData = [
  {
    title: "Reiki Training & Researches",
    subtitle: "Explore the transformative power of Reiki.",
    imageUrl: reikiImg,
  },
  {
    title: "Research on Living a Medicine-Free Life",
    subtitle: "Discover natural methods to enhance your health.",
    imageUrl: medicineFreeImg,
  },
  {
    title: "Expert Consultation and Research",
    subtitle: "Receive guidance from experienced professionals.",
    imageUrl: consultationImg,
  },
  {
    title: "Energio Land Treatment",
    subtitle: "Experience our holistic approach to healing.",
    imageUrl: energioImg,
  },
];

// Styled components

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding-top: 80px; /* Ensure spacing to avoid overlap */
  scroll-margin-top: 80px; /* Offset for fixed navbar */
  margin-bottom: 120px; /* Add margin below the header */
`;

const AnchorOffset = styled.div`
  content: "";
  display: block;
  height: 80px; /* Adjust for navbar height */
  margin-top: -80px; /* Offset section correctly */
`;

const HeaderInfo = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 2.5rem; /* Default font size */
  font-weight: bold;
  color: #04016a; /* Text color set to #04016a */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 10px #ffeb80; /* Glow effect */
  margin-bottom: 20px; /* Space below the title */
  padding: 10px 0;
  border-radius: 5px;

  /* Responsive font sizes */
  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  text-align: left;
  color: #34495e;
  background: rgba(255, 255, 0, 0.2);
  border-left: 5px solid #04016a;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  margin: 0;
  line-height: 1.6;
  transition: background 0.3s;

  &:hover {
    background: rgba(255, 255, 0, 0.4);
  }

  /* Responsive description text */
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of cards */
  gap: 15px; /* Space between cards */
  justify-content: center; /* Center the cards */

  /* Default to 4 cards in a row */
  @media (min-width: 1024px) {
    width: 100%;
  }

  /* For medium screens (tablets), set 2 cards per row */
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: space-between;
  }

  /* For small screens (phones), stack 1 card per row, reduce width of cards */
  @media (max-width: 768px) {
    flex-direction: column; /* Stack the cards vertically */
    align-items: center; /* Center the cards */
    gap: 10px; /* Adjust gap for stacked layout */
  }

  /* For screens between 778px and 1024px, set 2 cards per row */
  @media (max-width: 1024px) and (min-width: 778px) {
    flex-direction: row;
    justify-content: space-between; /* Space between cards */
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 23%; /* Default width for 4 cards in a row (approximately) */
  height: auto; /* Allow height to adjust based on content */
  min-height: 250px;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column; /* Stack content vertically */

  /* Ensure consistent size across cards */
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  /* For smaller mobile screens, reduce the card width */
  @media (max-width: 768px) {
    width: 85%; /* Make the cards smaller on mobile */
    height: auto; /* Allow height to adjust based on content */
    padding: 10px; /* Reduce padding */
  }

  /* For medium tablets: 2 cards per row */
  @media (max-width: 1024px) and (min-width: 778px) {
    width: 48%; /* Each card will take 48% of the row, allowing for 2 cards per row */
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 60%;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 15px;
  flex-grow: 1; /* Ensure content fills available space */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  h2 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }

  p {
    color: #34495e;
    font-size: 0.9rem;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
    word-wrap: break-word; /* Allow text to wrap inside */
  }
`;

const LearnMoreButtonContainer = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const LearnMoreButton = styled.button`
  background-color: #04016a;
  color: white;
  font-size: 1.2rem;
  padding: 12px 24px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;
  max-width: 200px; /* Limiting button width on larger screens */

  &:hover {
    background-color: #6e8cc3;
    transform: scale(1.05);
  }

  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    width: 80%; /* Button takes up 80% of screen width on mobile */
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    width: 70%;
  }
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: ${(props) => (props.show ? "block" : "none")};
  background-image: linear-gradient(45deg, #a67c00 70%, #04016a 30%);
  color: white;
  padding: 10px 20px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  cursor: pointer;
  z-index: 9999;
  transition: transform 0.3s, background-position 1s;

  /* Continuous flowing gradient effect */
  background-size: 400% 400%; /* Large background for animation */
  animation: gradientFlow 2s ease infinite;

  &:hover {
    transform: scale(1.1);
  }

  /* Keyframes to animate the gradient */
  @keyframes gradientFlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
