import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import LogoIcon from "../../assets/svg/Logo";

export default function Sidebar({ sidebarOpen, closeSidebar }) {
  return (
    <Wrapper className={`sidebar animate darkBg`} sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoIcon />
          <h1
            className="extraBold"
            style={{ lineHeight: "1.4" }} // Reduced margin to bring closer
          >
            Amruttam Reiki Yoga
          </h1>
        </div>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        {["home", "courses", "services", "products", "contact"].map((item) => (
          <li key={item} className="semiBold font15 pointer">
            <Link
              onClick={closeSidebar} // Close sidebar on link click
              to={item}
              spy={true}
              smooth={true}
              offset={-60}
              className="whiteColor"
              activeClass="active-link" // Add activeClass here
            >
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </Link>
          </li>
        ))}
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 80%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-80%")};
  z-index: 9999;
  transition: right 0.3s ease;
  background: linear-gradient(
    135deg,
    #04016a,
    #4b0082
  ); /* Added gradient background */
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2); /* Soft shadow to create depth */
  padding-top: 40px; /* Padding to avoid top being cramped */

  /* Sidebar visible for mobile and tablets (up to 768px) */
  @media (max-width: 768px) {
    padding: 20px 0;
    display: block;
  }

  /* Hide Sidebar for larger screens */
  @media (min-width: 769px) {
    display: none;
  }
`;

const SidebarHeader = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px; // Added margin for better spacing

  h1 {
    font-size: 1.8rem; // Slightly larger font for better visibility
    margin-left: 10px; // Reduced margin for closeness
    color: #fff;
    font-family: "Times New Roman", Times, serif; // Using a serif font for elegance

    @media (min-width: 768px) {
      font-size: 2rem; // Larger font for desktop views
    }
  }
`;

const UlStyle = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    margin: 15px 0;
    display: flex;
    justify-content: center;

    a {
      font-size: 1.5rem; /* Increased font size for better readability */
      color: #fff;
      text-decoration: none;
      padding: 10px;
      border-radius: 6px; /* Rounded corners for a soft look */
      transition: all 0.3s ease; /* Smooth transition for hover effects */

      @media (min-width: 768px) {
        font-size: 1.3rem; // Slightly smaller font on larger screens
      }

      &:hover {
        background-color: #ffeb80; /* Light yellow background on hover */
        color: #04016a; /* Darker text color */
        transform: translateX(5px); /* Slight horizontal shift for effect */
      }

      &.active-link {
        color: #ffeb80; /* Active link color */
        font-weight: bold;
        border-bottom: 2px solid #ffeb80;
        padding-bottom: 4px;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 30px 20px; // Adjust padding on larger screens for better layout
  }
`;
