import React, { useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import TestimonialBox from "../Elements/TestimonialBox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialSlider() {
  const sliderRef = useRef(null); // Reference for controlling the slider

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Disable the default arrows

    swipe: true, // Enable swipe functionality
    swipeToSlide: true, // Allow swiping between slides
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const testimonials = [
    {
      text: "I got a reference from one of my relatives about this institute and I wanted to learn reiki and healing practices. I can say it was my best decision to connect with them and started learning about reiki and spiritual knowledge. I can say it's the best intuition to learn reiki and It changed my course of life. I am using their products like Amrutam Reiki powered globules and Amrutam batti since 2 years and it gave me extraordinary results.",
      author: "Aditi Pariawala",
      stars: 5,
    },
    {
      text: "Joining this institute has been a life-changing experience for me. You will get to see an entirely different perspective of life and have a tool (Reiki) to find solutions to your perpetual problems. The standard of classes is excellent and the dedication attributed towards each student from these Reiki masters is way beyond expectations. Highly recommended to everyone for a better and meaningful life.",
      author: "V Lakhanpal",
      stars: 5,
    },
    {
      text: "I learned reiki in Amruttam research center last year. I am very happy with my experience as they give deep knowledge of this subject and it's a life-changing experience. They are always ready to guide you. I am happy that I chose this institute to learn Reiki from. Even when your course is over, they are ready to help you in every possible way related to reiki. Once you join this institute, they become like your family.",
      author: "Priyanka",
      stars: 5,
    },
    {
      text: "AMRUTTAM REIKI & YOGA INSTITUTE, is best research centre & training centre among its category in world. Simply amazing experience to be part of Amruttam family. Hats off to the teaching skills and there dedication for students (AMRUTTAM family members). We learn here Energy science, it's relativity with our physical, mental, spiritual body, and it's uses for transforming our life!! What We can achieve Healing of any disease, transforming personality, Acceptance to changes, which is toughest thing in the world Financial targets and endless achievement. Best part is we are trained here to achieve things using our own strength (independence) so go get discovered🕵. You are 1 step away towards enlightenment🌞.",
      author: "Kumarpal Shah",
      stars: 5,
    },
    {
      text: "I feel so blessed while writing about my experience taking 'Amruttam Reiki Power Gobules'. These Power Gobules are so much powerful & are certainly life-changing. Based on scientific findings and with constant universal energy flow, they were founded by Reiki Master Bharat Mistry. Amruttam RPG has proven to be my ultimate solution to every health and life issue.",
      author: "Vishakha Chauhan",
      stars: 5,
    },
  ];

  // Custom navigation functions
  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <SliderWrapper>
      <Slider {...settings} ref={sliderRef}>
        {testimonials.map((testimonial, index) => (
          <LogoWrapper key={index} className="flexCenter">
            <TestimonialBox
              text={testimonial.text}
              author={testimonial.author}
              stars={testimonial.stars}
            />
          </LogoWrapper>
        ))}
      </Slider>

      {/* Custom navigation buttons */}
      <NavButton onClick={handlePrev} className="prevButton">
        &lt; {/* Backward arrow */}
      </NavButton>
      <NavButton onClick={handleNext} className="nextButton">
        &gt; {/* Forward arrow */}
      </NavButton>
    </SliderWrapper>
  );
}

const SliderWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px; /* Add padding to prevent overflow */

  /* Add extra spacing for navigation buttons */
  margin-bottom: 80px;

  @media (max-width: 1206px) {
    padding: 0 20px; /* Reduce padding for narrower screens */
  }

  @media (max-width: 768px) {
    margin-bottom: 100px; /* Additional spacing for smaller screens */
    padding: 0 15px; /* Compact padding */
  }
`;

const LogoWrapper = styled.div`
  width: 90%;
  height: auto;
  padding: 0 5%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(135deg, #ff7f50, #ff6347);
  color: white;
  border: none;
  font-size: 30px;
  padding: 15px;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  /* Adjust button positioning for large screens */
  &.prevButton {
    left: 1%; /* Use percentage for consistent placement */
  }

  &.nextButton {
    right: 1%; /* Use percentage for consistent placement */
  }

  /* For medium-sized screens */
  @media (max-width: 1206px) {
    &.prevButton {
      left: 1%; /* Adjust for narrower screens */
    }
    &.nextButton {
      right: 1%; /* Adjust for narrower screens */
    }
  }

  /* For smaller screens (Mobile Devices) */
  @media (max-width: 768px) {
    top: auto;
    bottom: -60px; /* Move buttons below slider */
    left: 50%;
    transform: translateX(-60%);
    width: 50px;
    height: 50px;
    font-size: 20px;
    padding: 10px;

    &.prevButton {
      left: 30%; /* Align buttons below slider */
    }

    &.nextButton {
      left: 70%; /* Align buttons below slider */
    }
  }
`;
