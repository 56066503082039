import React, { useRef, useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import HeaderImage from "../../assets/img/Dr. Usui.png";
import { Link } from "react-router-dom";

export default function Header() {
  const [imageWidth, setImageWidth] = useState(0);
  const imageRef = useRef(null);

  useEffect(() => {
    // Set initial image width
    if (imageRef.current) {
      setImageWidth(imageRef.current.offsetWidth);
    }

    // Optional: Update image width on window resize
    const handleResize = () => {
      if (imageRef.current) {
        setImageWidth(imageRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide className="flexCenter">
          <ContentWrapper>
            <Heading>
              THE IMMORTAL SOLUTION AND ULTIMATE NECESSITY OF ALL YOUR PROBLEMS.
            </Heading>
            <HeaderP className="semiBold">
              We offer holistic healing and wellness through the powerful
              combination of Reiki and yoga, helping you achieve balance &{" "}
              <Highlight>
                <span className="nowrap">MEDICINE-FREE LIFE.</span>
              </Highlight>
            </HeaderP>
            <AboutButton to="/about">About Us</AboutButton>
          </ContentWrapper>
        </LeftSide>
        <RightSide>
          <ImageWrapper imageWidth={imageWidth}>
            <Img
              ref={imageRef}
              className="radius8"
              src={HeaderImage}
              alt="office"
            />
            <AuthorTextWrapper>
              <AuthorText>Dr. Mikao Usui</AuthorText>
            </AuthorTextWrapper>
          </ImageWrapper>
        </RightSide>
      </Wrapper>
    </>
  );
}

const Heading = styled.h1`
  font-family: "Times New Roman", serif;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--primary-color, #04016a);
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  margin: 0;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 2rem;
  }

  @media (min-width: 1024px) {
    font-size: 2.2rem;
  }
`;

const AboutButton = styled(Link)`
  --color: #04016a;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  margin-top: 20px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s, background 0.5s, border 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background: var(--color);

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }

  &:hover {
    color: var(--color);
    background: #fff;
    border-color: var(--color);

    &::before {
      top: -30px;
      left: -30px;
      background: #fff;
    }
  }

  &:active::before {
    background: #3a0ca3;
    transition: background 0s;
  }
`;

const Wrapper = styled.section`
  padding-top: 100px;
  width: 100%;
  min-height: 840px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
  animation: fadeInUp 1s ease-out;

  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 120px;
  }

  @media (max-width: 767px) {
    margin-top: 5px;
    padding-top: 60px;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  text-align: left;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;

  @media (max-width: 960px) {
    width: 100%;
    text-align: center;
    order: 2;
    margin: 50px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;

  h1 {
    margin: 0;
  }

  p {
    margin-top: 20px;
    display: inline;
  }

  @media (max-width: 960px) {
    align-items: center;
  }
`;

const Highlight = styled.span`
  color: #04016a;
  font-family: "Times New Roman", serif;
  font-size: 1.6rem;
  background: rgba(255, 255, 0, 0.3);
  padding: 2px 5px;
  border-radius: 10px;
  font-weight: bold;

  .nowrap {
    white-space: nowrap;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  width: 50%;
  position: relative;

  @media (max-width: 960px) {
    width: 100%; /* Full width for smaller screens */
    margin-top: 30px;
  }
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  font-size: 1.28rem;
  color: #333;
  margin: 0;

  @media (max-width: 960px) {
    text-align: center;
    max-width: 100%;
    padding: 15px 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the image and AuthorTextWrapper horizontally */
  justify-content: center; /* Center content vertically */
  position: relative;
  width: 100%;
  max-width: 426px; /* Limit the maximum width */
  margin-top: 40px;

  @media (max-width: 960px) {
    max-width: 90%; /* Scale down for smaller screens */
    margin-top: 30px;
  }
`;

const Img = styled.img`
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  width: 426px; /* Fixed width for the image */
  height: auto; /* Maintain aspect ratio */

  @media (max-width: 960px) {
    width: 90%; /* Scale down for smaller screens */
  }

  @media (max-width: 560px) {
    width: 80%; /* Further scaling for very small screens */
  }
`;

const AuthorTextWrapper = styled.div`
  width: 100%; /* Match the width of ImageWrapper */
  background: linear-gradient(to bottom, #ffeb3b, #ffffff);
  text-align: center;
  padding: 6px 0;
  z-index: 9;
  border-radius: 10px;
  margin-top: 10px;

  display: flex; /* Use flexbox for text alignment */
  justify-content: center; /* Ensure text is horizontally centered */

  @media (max-width: 960px) {
    width: 90%; /* Match the scaled image width */
  }

  @media (max-width: 560px) {
    width: 80%; /* Match the further scaled image width */
  }
`;

const AuthorText = styled.p`
  font-size: 25px;
  color: #04016a;
  margin: 0;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  background: transparent;
`;

const GlobalStyle = createGlobalStyle`
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
