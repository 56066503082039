import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./components/Nav/Sidebar"; // Update paths accordingly
import DesktopNavbar from "./components/Nav/DesktopNavbar";
import TopNavbar from "./components/Nav/TopNavbar";

const MainContent = ({ toggleSidebar, closeSidebar, sidebarOpen }) => {
  const location = useLocation();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  // List of routes where you do NOT want to show the TopNavbar and DesktopNavbar
  const excludedRoutes = [
    "/about",
    "/store",
    "/all-services",
    "/reiki-course",
    "/reiki-1st-and-2nd-degree",
    "/reiki-3rd-degree",
    "/pendulum",
    "/reiki-crystal-basic",
    "/reiki-personalized",
    "/arpg",
    "/amruttam-batti",
    "/life-protector",
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isDesktop && !excludedRoutes.includes(location.pathname) && (
        <>
          <DesktopNavbar />
          <TopNavbar toggleSidebar={toggleSidebar} />
        </>
      )}
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );
};

export default MainContent;
