// routes.js

import React from "react";
import { Route, Routes } from "react-router-dom";

import Landing from "./screens/Landing";
import Store from "./components/Elements/Store";
import About from "./components/Elements/About";

import Reiki from "./components/courses/Reiki";
import Reiki1stAnd2ndDegree from "./components/courses/Reiki1stAnd2ndDegree";
import Reiki3rdDegree from "./components/courses/Reiki3rdDegree";
import Pendulum from "./components/courses/Pendulum";
import ReikiCrystalBasic from "./components/courses/ReikiCrystalBasic";
import ReikiPersonalized from "./components/courses/ReikiPersonalized";
import ARPG from "./components/products/ARPG";
import AmruttamBatti from "./components/products/AmruttamBatti";
import LifeProtector from "./components/products/LifeProtector";
import AllServices from "./components/services/AllServices";

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/all-services" element={<AllServices />} />
      <Route path="/store" element={<Store />} />
      <Route path="/about" element={<About />} />
      <Route path="/reiki-course" element={<Reiki />} />
      <Route
        path="/reiki-1st-and-2nd-degree"
        element={<Reiki1stAnd2ndDegree />}
      />
      <Route path="/reiki-3rd-degree" element={<Reiki3rdDegree />} />
      <Route path="/pendulum" element={<Pendulum />} />
      <Route path="/reiki-crystal-basic" element={<ReikiCrystalBasic />} />
      <Route path="/reiki-personalized" element={<ReikiPersonalized />} />
      <Route path="/arpg" element={<ARPG />} />
      <Route path="/amruttam-batti" element={<AmruttamBatti />} />
      <Route path="/life-protector" element={<LifeProtector />} />
    </Routes>
  );
};

export default RoutesComponent;
