import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import ContactImg from "../../assets/img/ContactUs.png";

// Email validation function
const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    subject: "",
    message: "",
  });

  const [toastMessage, setToastMessage] = useState(""); // Toast message state

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Send email using emailjs
  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(""); // Reset error message

    // Simple client-side email validation
    if (!validateEmail(formData.email)) {
      setErrorMessage("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    const params = {
      from_name: formData.fname,
      email_id: formData.email,
      subject: formData.subject,
      message: formData.message,
    };

    emailjs
      .send("service_91furwx", "template_8hg0btc", params, "gvBNajotyR4XbfI3G")
      .then((res) => {
        setFormData({ fname: "", email: "", subject: "", message: "" });
        showToast("Your message has been sent!");
      })
      .catch((error) => {
        setErrorMessage(
          "Sorry, there was an issue sending your message. Please try again."
        );
        console.error("Error sending email:", error);
      })
      .finally(() => setLoading(false)); // Stop loading
  };

  // Show toast message
  const showToast = (message) => {
    setToastMessage(message);
    setTimeout(() => {
      setToastMessage(""); // Hide toast after 3 seconds
    }, 3000);
  };

  return (
    <Wrapper id="contact">
      <div className="">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font15">
              <br />
              Get in touch with us for any questions, guidance, or support on
              your journey with Amruttam Reiki Yoga.
              <br />
            </p>
          </HeaderInfo>
          <FormContainer>
            {/* Contact Image Box */}
            <ContactImgBox>
              <img src={ContactImg} alt="Contact Us" />
            </ContactImgBox>
            <Form onSubmit={sendEmail}>
              <Label htmlFor="fname">FULL NAME</Label>
              <Input
                id="fname"
                type="text"
                name="fname"
                value={formData.fname}
                onChange={handleChange}
                required
                placeholder="Enter your first name"
                aria-label="Full Name"
              />
              <Label htmlFor="email">EMAIL</Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder="Enter your email"
                aria-label="Email Address"
              />
              <Label htmlFor="subject">SUBJECT</Label>
              <Input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                placeholder="Subject of your message"
                aria-label="Subject"
              />
              <Label htmlFor="message">MESSAGE</Label>
              <TextArea
                rows="4"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Send us your inquiry"
                aria-label="Message"
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <SubmitWrapper>
                <ButtonInput
                  type="submit"
                  value={loading ? "Sending..." : "Send Message"}
                  disabled={loading}
                />
              </SubmitWrapper>
            </Form>
          </FormContainer>
          <MapContainer>
            <iframe
              title="Map showing the location of Amruttam Reiki Yoga"
              aria-label="Location of Amruttam Reiki Yoga"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.856186878623!2d72.76684757584368!3d21.15812078332187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04e0adf773199%3A0x9b9645249cac5120!2sAmruttam%20Research%20Institute%20for%20Reiki%20and%20Yoga%20Pvt.%20Ltd.%20(Amruttam%20Reiki%20Yoga)!5e0!3m2!1sen!2sin!4v1729507394423!5m2!1sen!2sin"
              style={{ border: 0, width: "100%", height: "400px" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </MapContainer>
        </div>
      </div>

      {/* Toast Message */}
      {toastMessage && <ToastMessage>{toastMessage}</ToastMessage>}
    </Wrapper>
  );
}

// Styled Components
const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding-top: 80px; /* Ensure spacing to avoid overlap */
  scroll-margin-top: 80px; /* Offset for fixed navbar */
  margin-bottom: 60px; /* Add margin below the header */
`;

const HeaderInfo = styled.div`
  text-align: center;
  padding: 50px 0 30px 0;
  margin-bottom: 40px;
  font-weight: bold;
  color: #04016a;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3),
    /* Soft shadow for text */ 0px 0px 10px #ffeb80; /* Glow effect */

  @media (max-width: 768px) {
    padding: 50px 0 20px 0;
  }

  @media (max-width: 480px) {
    padding: 40px 0 10px 0;
  }
`;

const FormContainer = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 0;
  }
`;

const Label = styled.label`
  color: #333;
  margin-bottom: 5px;
`;

const InputBase = styled.input`
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #707070;
  margin-bottom: 20px;
  height: 40px;

  &:focus {
    border-bottom: 2px solid #04016a;
  }
`;

const Input = styled(InputBase).attrs({ as: "input" })``;
const TextArea = styled(InputBase).attrs({ as: "textarea" })``;

const ButtonInput = styled.input`
  border: none;
  background: linear-gradient(90deg, #04016a, #070768, #515195);
  background-size: 300% 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s;

  @media (max-width: 768px) {
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ContactImgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
  margin-right: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const MapContainer = styled.div`
  margin-top: 20px;
  iframe {
    height: 400px;
    @media (max-width: 768px) {
      height: 300px;
    }

    @media (max-width: 600px) {
      height: 250px;
    }
  }
`;

const ToastMessage = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  animation: toastIn 0.5s ease-in-out, toastOut 0.5s ease-in-out 2.5s forwards;

  @keyframes toastIn {
    0% {
      transform: translateX(-50%) translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateX(-50%) translateY(0);
      opacity: 1;
    }
  }

  @keyframes toastOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(-50%) translateY(20px);
    }
  }
`;
