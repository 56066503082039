import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll"; // Link from react-scroll
import { Link as RouterLink } from "react-router-dom"; // Link from react-router-dom
import styled from "styled-components";
import LogoIcon from "../../assets/svg/Logo";

export default function DesktopNavbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // For mobile menu toggle

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Wrapper>
      <NavInner>
        <LogoContainer>
          <RouterLink
            to="/"
            style={{ display: "flex", alignItems: "center" }}
            onClick={(e) => {
              // If already on the homepage, trigger smooth scroll
              if (window.location.pathname === "/") {
                e.preventDefault();
                document
                  .getElementById("top")
                  ?.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            <LogoIcon />
            <h1
              className="font20 extraBold"
              style={{ marginLeft: "10px", lineHeight: "1.4" }}
            >
              Amruttam Research Institute for Reiki and Yoga
              <br />
              <span style={{ display: "block" }}>Pvt. Ltd.</span>
            </h1>
          </RouterLink>
        </LogoContainer>

        {/* Toggle button for mobile */}
        <HamburgerIcon onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </HamburgerIcon>

        <NavLinks className={isMenuOpen ? "open" : ""}>
          {["home", "courses", "services", "products", "contact"].map(
            (item) => (
              <NavItem key={item}>
                <StyledLink
                  activeClass="active"
                  to={item}
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                  onClick={() => setIsMenuOpen(false)} // Close menu on click
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </StyledLink>
              </NavItem>
            )
          )}
        </NavLinks>
      </NavInner>
    </Wrapper>
  );
}

// Styled Components

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff; /* Solid white background for better contrast */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 10px 0; /* Adjust padding to give more vertical space */

  @media (max-width: 1024px) {
    padding: 15px 0; /* Slightly increase padding for medium screens */
  }

  /* Hide Desktop Navbar below 769px */
  @media (max-width: 768px) {
    display: none;
  }

  /* Visible for larger screens */
  @media (min-width: 769px) {
    display: flex;
  }
`;

const NavInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  min-height: 80px; /* Increase minimum height */

  @media (max-width: 767px) {
    flex-direction: column;
    min-height: 100px; /* More height for small screens */
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping */
  max-width: 100%;

  h1 {
    font-size: clamp(16px, 2vw, 24px); /* Dynamically adjust font size */
    margin-left: 10px;
    line-height: 1.4;
    white-space: normal; /* Allow wrapping */
    text-align: left;

    @media (max-width: 1024px) {
      text-align: center;
      font-size: 18px; /* Adjust font size */
    }

    @media (max-width: 767px) {
      font-size: 16px;
      text-align: center;
      word-break: break-word; /* Handle word-breaking */
    }
  }
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;

  /* Hide menu on small screens by default */
  @media (max-width: 767px) {
    display: ${(props) => (props.className === "open" ? "flex" : "none")};
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
    background-color: #fff;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  }
`;

const NavItem = styled.li`
  margin: 0 15px;
  padding: 10px 0;

  @media (max-width: 767px) {
    margin: 10px 0;
  }
`;

const StyledLink = styled(ScrollLink)`
  text-decoration: none;
  color: #04016a;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  display: inline-block;

  /* 3D white shadow effect */
  text-shadow: 2px 2px 5px rgba(255, 255, 255, 0.7);

  &.active {
    color: #04016a; /* Active text color */
    font-weight: bold;
    background-color: #fff; /* Active background color */
    padding: 5px 10px;
    border-radius: 4px;
  }

  &:hover {
    color: #04016a;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
  }

  /* Smooth transition */
  transition: all 0.3s ease;
`;

const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;

  .bar {
    width: 30px;
    height: 4px;
    background-color: #04016a;
    border-radius: 5px;
  }

  @media (max-width: 767px) {
    display: flex;
  }
`;
