import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import { Link as RouterLink } from "react-router-dom"; // Link from react-router-dom

export default function TopNavbar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={handleCloseSidebar} />
      {sidebarOpen && <Backdrop onClick={handleCloseSidebar} />}
      <Wrapper className="flexCenter animate">
        <NavInner className="container flexSpaceBetween">
          <LogoContainer>
            <RouterLink
              to="/"
              style={{ display: "flex", alignItems: "center" }}
              onClick={(e) => {
                // If already on the homepage, trigger smooth scroll
                if (window.location.pathname === "/") {
                  e.preventDefault();
                  document
                    .getElementById("top")
                    ?.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <LogoIcon />
              <h1
                className="font20 extraBold"
                style={{ marginLeft: "10px", lineHeight: "1.4" }}
              >
                Amruttam Reiki Yoga
              </h1>
            </RouterLink>
          </LogoContainer>

          {/* Mobile Hamburger Menu */}
          <BurgerWrapper className="pointer" onClick={handleToggleSidebar}>
            <BurgerIcon />
          </BurgerWrapper>

          {/* This could be the full navbar when on larger screens (not visible on mobile) */}
          <NavLinks className="desktopNav">
            <ul className="flex">
              {["Home", "Courses", "Services", "Products", "Contact"].map(
                (item, index) => (
                  <li key={index}>
                    <a href={`#${item.toLowerCase()}`} className="navLink">
                      {item}
                    </a>
                  </li>
                )
              )}
            </ul>
          </NavLinks>
        </NavInner>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff;

  /* Gradient overlay */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 223, 136, 0.15),
      rgba(4, 1, 106, 0.1),
      rgba(255, 255, 255, 0)
    );
    z-index: -1; /* Place the gradient behind the navbar */
  }

  /* Show wrapper (with mobile style) for screens up to 768px */
  @media (max-width: 768px) {
    display: flex; /* Ensure mobile navbar is visible at 768px and below */
  }

  /* Hide wrapper (for desktop) at screen size 769px and above */
  @media (min-width: 769px) {
    display: none;
  }
`;

const NavLinks = styled.div`
  display: none; /* Default: Hidden on small screens */

  .desktopNav {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin: 0 15px;
    }

    .navLink {
      text-decoration: none;
      color: #04016a;
      font-weight: bold;
      font-size: 1rem;
      padding: 8px 0;
      &:hover {
        color: #ffeb80;
      }
    }
  }

  /* Show desktop navigation only for screens 769px and above */
  @media (min-width: 769px) {
    display: flex; /* Show Desktop Navigation */
  }

  /* Hide desktop navigation for small screens (up to 768px) */
  @media (max-width: 768px) {
    display: none; /* Hide the desktop navbar at 768px and below */
  }
`;

const BurgerWrapper = styled.button`
  outline: none;
  border: 0;
  background-color: transparent;
  height: 100%;
  padding: 0;

  /* Show burger menu on screens up to 768px */
  @media (max-width: 768px) {
    display: block; /* Show burger menu on mobile screens */
  }

  /* Hide burger menu on screens 769px and above */
  @media (min-width: 769px) {
    display: none;
  }
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  @media (max-width: 760px) {
    padding: 0 15px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px; /* Default small space for larger screens */

  @media (max-width: 768px) {
    margin-top: 15px; /* Space for smaller screens (mobile) */
  }

  @media (max-width: 480px) {
    margin-top: 20px; /* More space for very small screens (portrait mobile) */
  }
`;
